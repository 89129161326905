/* Main.css */
.floating-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(circle, rgba(255,255,255,0.1), rgba(0,0,0,0.1));
  z-index: -1;
  animation: moveBackground 10s linear infinite;
}

@keyframes moveBackground {
  0% { transform: translate(0, 0); }
  50% { transform: translate(50px, 50px); }
  100% { transform: translate(0, 0); }
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}
