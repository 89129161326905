@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Pretendard;
  font-weight: 100;
  src: local("Pretendard Thin"),
    url("/public/assets/fonts/Pretendard-Thin.woff") format("woff"),
    url("/public/assets/fonts/Pretendard-Thin.woff2") format("woff2");
}

@font-face {
  font-family: Pretendard;
  font-weight: 200;
  src: local("Pretendard ExtraLight"),
    url("/public/assets/fonts/Pretendard-ExtraLight.woff") format("woff"),
    url("/public/assets/fonts/Pretendard-ExtraLight.woff2") format("woff2");
}

@font-face {
  font-family: Pretendard;
  font-weight: 300;
  src: local("Pretendard Light"),
    url("/public/assets/fonts/Pretendard-Light.woff") format("woff"),
    url("/public/assets/fonts/Pretendard-Light.woff2") format("woff2");
}

@font-face {
  font-family: Pretendard;
  font-weight: 400;
  src: local("Pretendard Regular"),
    url("/public/assets/fonts/Pretendard-Regular.woff") format("woff"),
    url("/public/assets/fonts/Pretendard-Regular.woff2") format("woff2");
}

@font-face {
  font-family: Pretendard;
  font-weight: 500;
  src: local("Pretendard Medium"),
    url("/public/assets/fonts/Pretendard-Medium.woff") format("woff"),
    url("/public/assets/fonts/Pretendard-Medium.woff2") format("woff2");
}

@font-face {
  font-family: Pretendard;
  font-weight: 600;
  src: local("Pretendard SemiBold"),
    url("/public/assets/fonts/Pretendard-SemiBold.woff") format("woff"),
    url("/public/assets/fonts/Pretendard-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: Pretendard;
  font-weight: 700;
  src: local("Pretendard Bold"),
    url("/public/assets/fonts/Pretendard-Bold.woff") format("woff"),
    url("/public/assets/fonts/Pretendard-Bold.woff2") format("woff2");
}

@font-face {
  font-family: Pretendard;
  font-weight: 800;
  src: local("Pretendard ExtraBold"),
    url("/public/assets/fonts/Pretendard-ExtraBold.woff") format("woff"),
    url("/public/assets/fonts/Pretendard-ExtraBold.woff2") format("woff2");
}

@font-face {
  font-family: Pretendard;
  font-weight: 900;
  src: local("Pretendard Black"),
    url("/public/assets/fonts/Pretendard-Black.woff") format("woff"),
    url("/public/assets/fonts/Pretendard-Black.woff2") format("woff2");
}

html, body, #root {
  height: 100%;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

::before,
::after {
  border-width: 0;
  border-style: solid;
}
